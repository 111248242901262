module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Wansite","name":"Wansite | Create you own site in minutes","description":"Put all you content in just one place and don't forget to share with the world","lang":"en","start_url":"/","display":"fullscreen","background_color":"#FFFFFF","icon":"src/assets/images/favicon.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"024823779c670904cbe828fc58ab3c77"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
